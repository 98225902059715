import SearchBar from './search_bar';

const AutocompleteSearchBar = SearchBar.extend({
    events() {
        return _.extend({}, _.result(SearchBar.prototype, 'events'), {
            'blur .js-quickSearchInput': 'blur',
            'focus .js-quickSearchInput': 'focus',
            'keydown .js-quickSearchInput': 'keydown',
        });
    },

    initialize({ el, toggleSearchEl, query, autocompleteBus }) {
        let options = {};
        if (toggleSearchEl) {
            options.$toggleSearch = toggleSearchEl;
        }

        SearchBar.prototype.initialize.call(this, { el, query, options });
        this.autocompleteBus = autocompleteBus;
        this.listenTo(
            this.autocompleteBus,
            'quicksearch:fill',
            this.updateText
        );
    },

    updateText(text) {
        this.$input.val(text);
    },

    shouldTriggerBlur(ev) {
        const classes = ['js-autocompletionItem', 'js-mobileSearch'];
        const targetViaDocument = document.activeElement;
        const targetViaEvent = ev.relatedTarget;
        return (
            classes.every(
                (itemClass) =>
                    !targetViaDocument.classList.contains(itemClass) &&
                    !(
                        targetViaEvent &&
                        targetViaEvent.classList.contains(itemClass)
                    )
            ) || false
        );
    },

    keydown(ev) {
        if (ev.key === 'ArrowUp' || ev.key === 'ArrowDown') {
            return false;
        }
        if (ev.key === 'Tab') {
            this.blurAutocomplete();
        }
        return void 0;
    },

    blurAutocomplete() {
        this.autocompleteBus.trigger('quicksearch:blur');
    },

    blur(ev) {
        setTimeout(() => {
            if (this.shouldTriggerBlur(ev)) {
                this.blurAutocomplete();
            }
        }, 1);
    },

    focus(ev) {
        this.query.set('text', ev.target.value);
        this.autocompleteBus.trigger('quicksearch:focus');
    },

    keyupMap: new Map([
        [
            'ArrowUp',
            (self) =>
                self.autocompleteBus.trigger('quicksearch:highlightItem', -1),
        ],
        [
            'ArrowDown',
            (self) =>
                self.autocompleteBus.trigger('quicksearch:highlightItem', 1),
        ],
        [...SearchBar.prototype.keyupMap],
    ]),
});

export default AutocompleteSearchBar;
