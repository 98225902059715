import BaseView from '../../../views/base';

const _ = window._;

const SearchBar = BaseView.extend({
    events: {
        'click .js-doQuickSearch': 'submitForm',
        'keyup .js-quickSearchInput': 'keyup',
        'change .js-quickSearchInput': 'change',
        'input .js-quickSearchInput': 'input',
        'click .js-clearSearch': 'clearSearch',
        'tap .js-clearSearch': 'clearSearch',
        'keyup .js-clearSearch': 'isClearSearch',
    },

    initialize({ el, query, options }) {
        this._injectCustomFunctionality();

        this.setElement(el);
        this.delegateEvents();
        this.query = query;

        this.$input = this.$el.find('.js-quickSearchInput');
        this.$form = this.$el.find('.js-quickSearchForm');
        this.$toggleSearch = this.$el.find('.js-toggleSearch');
        this.$clearSearch = this.$el.find('.js-clearSearch');

        // LHTNG-1215 - Sync any input provided statically from the form markup
        this.query.set('text', this.$input.val());

        _.extend(this, options || {});

        if (this.$toggleSearch) {
            this.$toggleSearch.on('click keypress', (ev) => {
                this.toggleSearch(ev);
            });
        }

        if (this.$el.find('js-desktopSearchContainer')) {
            $('body').on('click', (e) => {
                if (
                    (!$(e.target).hasClass('js-toggleSearch') ||
                        !$(e.target).hasClass('icon-search-nav')) &&
                    !$(document.activeElement).parents(
                        '.js-desktopSearchContainer'
                    ).length
                ) {
                    this.hideSearchDropBox();
                }
            });

            $('.js-desktopSearchContainer').on('click', (e) => {
                e.stopPropagation();
            });

            $('.js-desktopSearchContainer').on('keydown click', (e) => {
                if (
                    ((e.key === 'Tab' || e.type === 'click') &&
                        $(e.target).hasClass('js-desktopAdvancedSearch') &&
                        !e.shiftKey) ||
                    e.key === 'Escape'
                ) {
                    this.hideSearchDropBox();
                }
            });
        }
    },

    hideSearchDropBox() {
        $('.js-desktopSearchContainer').hide();
        $('.js-desktopSearchContainer').attr('aria-hidden', 'true');
        $('.js-toggleSearch').attr('aria-expanded', 'false');
    },

    _injectCustomFunctionality() {
        $.fn.setCursorToTextEnd = function () {
            const $initialVal = this.val();
            this.val('').val($initialVal);
        };
    },

    submitForm(ev) {
        if (this.query.isValidForSearch()) {
            this.$form.submit();
        } else {
            ev.stopPropagation();
            ev.preventDefault();
        }
    },

    keyup(ev) {
        this.toggleClearIcon();

        if (this.keyupMap.has(ev.key)) {
            this.keyupMap.get(ev.key)(this);
        } else {
            this.query.set('text', ev.target.value);
            ev.stopPropagation();
            ev.preventDefault();
        }
    },

    change(ev) {
        this.toggleClearIcon();

        if (ev.target.value !== this.query.get('text')) {
            this.query.set('text', ev.target.value);
            ev.stopPropagation();
            ev.preventDefault();
        }
    },

    input(ev) {
        this.toggleClearIcon();
        if (this.$input.val() !== '') {
            this.query.set('text', ev.target.value);
        }
        if ($(ev.target).hasClass('Nav-searchInput')) {
            $('.MobileNav-searchInput').val(ev.target.value);
        } else {
            $('.Nav-searchInput').val(ev.target.value);
        }
        ev.stopPropagation();
        ev.preventDefault();
    },

    toggleClearIcon() {
        if (this.$input.val()) {
            this.$clearSearch.show();
        } else {
            this.$clearSearch.hide();
        }
    },

    clearSearch() {
        this.query.set('text', '');
        this.$input.val('').focus();
        $('#nav-search-mobile.js-quickSearchInput').val('');
        this.$clearSearch.hide();
    },

    isClearSearch(e) {
        if (e.key === 'Enter') {
            this.clearSearch();
        }
    },

    keyupMap: new Map([['Enter', (self) => self.submitForm()]]),

    toggleSearch(ev) {
        if (ev.key === 'Space' || ev.key === 'Enter' || ev.type === 'click') {
            this.$el.toggle();
            this.$el.attr(
                'aria-hidden',
                this.$el.attr('aria-hidden') === 'true' ? 'false' : 'true'
            );
            this.$input.focus().setCursorToTextEnd();
            this.$toggleSearch.attr(
                'aria-expanded',
                this.$toggleSearch.attr('aria-expanded') === 'true'
                    ? 'false'
                    : 'true'
            );
        }

        this.toggleClearIcon();

        if (toaster) toaster._stickToast();
    },
});

export default SearchBar;
